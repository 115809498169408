import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import { connect } from 'react-redux'
import {
  Header,
  Container,
  Segment,
  Grid,
  Item,
  Divider,
  Icon,
  List,
  Button
} from 'semantic-ui-react'
import { Link } from '../locales'
import TextTruncate from 'react-text-truncate'
import { withTranslation } from 'react-i18next'
import { EmailShareButton } from 'react-share'

import GratisForm from '../components/GratisForm/GratisForm'
import Layout from '../components/Layouts/PageLayout'
import ConfirmModal from '../components/Modals/ConfirmModal'
import { getFluidImage } from '../utils/images'
import { localizeLink } from '../utils/helpers'

import { wishlistActions } from '../components/Wishlist/state'
import { modalActions } from '../components/Modals/state'

const removeAllModalId = 'wishlistRemoveAll'

class WishlistPage extends Component {
  render () {
    const {
      location,
      wishlist,
      wishlistRemoveItem,
      wishlistRemoveAll,
      toggleModal,
      t,
      i18n
    } = this.props
    const wishlistItems = Object.values(wishlist)
    const wishlistLinks = wishlistItems
      .map(
        ({ title, country, url }) =>
          `${title}, ${country}\n${location.origin}${localizeLink(
            url,
            i18n.language
          )}`
      )
      .join('\n\n')

    console.log('wishlistLinks', wishlistLinks)

    return (
      <>
        <Helmet>
          <title>{t('wishlist.header.title')}</title>
        </Helmet>

        <Layout>
          <Segment basic vertical padded='very' className='lightgrey' inverted>
            <Container textAlign='center'>
              <Header as='h1' color='red'>
                {t('wishlist.header.title')}
              </Header>

              <Header as='h4'>{t('wishlist.description')}</Header>

              <List horizontal>
                {wishlistItems.length && (
                  <List.Item>
                    <List.Content style={{ cursor: 'pointer' }}>
                      <EmailShareButton
                        url={wishlistLinks}
                        subject={t('wishlist.mail.subject')}
                      >
                        <Icon name='mail' size='large' />
                        {t('wishlist.forward')}
                      </EmailShareButton>
                    </List.Content>
                  </List.Item>
                )}

                {wishlistItems.length && (
                  <List.Item onClick={() => toggleModal(removeAllModalId)}>
                    <List.Content style={{ cursor: 'pointer' }}>
                      <Icon className='ta-trash' size='large' />
                      {t('wishlist.header.remove')}
                    </List.Content>
                  </List.Item>
                )}
              </List>
              <Divider hidden />
              <ConfirmModal
                id={removeAllModalId}
                onConfirm={wishlistRemoveAll}
                text={t('wishlist.header.modal')}
              />
            </Container>

            <Grid stackable container>
              <Grid.Column computer={11} tablet={16} mobile={16}>
                <Item.Group divided>
                  {wishlistItems.map(
                    ({ id, url, title, intro, country, image }) => (
                      <Item key={id}>
                        <Item.Image size='medium'>
                          <Img
                            loading='lazy'
                            fadeIn={false}
                            fluid={getFluidImage({
                              cloudinary: image,
                              maxWidth: 300
                            })}
                          />
                        </Item.Image>

                        <Item.Content>
                          <Item.Meta>
                            <Icon className='ta-locationmark' size='large' />{' '}
                            {country}
                          </Item.Meta>
                          <Item.Header color='red'>
                            <Link to={url} lang={i18n.language}>
                              {title}
                            </Link>
                          </Item.Header>
                          <Item.Description>
                            <TextTruncate
                              text={intro}
                              line={4}
                              truncateText='…'
                              textTruncateChild={
                                <a href={url}>
                                  <Icon
                                    className='ta-arrow-1-right'
                                    color='red'
                                  />
                                </a>
                              }
                            />
                          </Item.Description>
                          <Item.Extra>
                            <List horizontal>
                              <List.Item>
                                <List.Content style={{ cursor: 'pointer' }}>
                                  <EmailShareButton
                                    url={`${location.origin}${url}`}
                                    subject={`${t(
                                      'wishlist.mail.subject'
                                    )} - ${title}`}
                                    body={`${title}\n${location.origin}${url}`}
                                  >
                                    {t('wishlist.send')}{' '}
                                    <Icon name='mail' size='large' />
                                  </EmailShareButton>
                                </List.Content>
                              </List.Item>
                              <List.Item
                                style={{ cursor: 'pointer' }}
                                onClick={() => toggleModal(id)}
                              >
                                <List.Content>
                                  {t('wishlist.remove')}{' '}
                                  <Icon className='ta-trash' size='large' />
                                </List.Content>
                              </List.Item>
                            </List>
                            <ConfirmModal
                              id={id}
                              onConfirm={() => wishlistRemoveItem(id)}
                              text={t('wishlist.modal')}
                            />
                          </Item.Extra>
                        </Item.Content>
                      </Item>
                    )
                  )}

                  {wishlistItems.length ? (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                      <Header as='h4'>{t('wishlist.addMore')}</Header>
                      <Link
                        to={`/country-regions/all/destinations/all`}
                        lang={i18n.language}
                      >
                        <Button
                          size='large'
                          color='red'
                          icon
                          labelPosition='right'
                          inverted
                          onClick={this.handleShowAll}
                        >
                          {t('country-page.show-all')}
                          <Icon name='ta-arrow-1-right' />
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center', marginTop: '50px' }}>
                      {' '}
                      <Header as='h4'>
                        {t('wishlist.empty')
                          .split('/n')
                          .map((line, key) => (
                            <div key={key}>{line}</div>
                          ))}
                      </Header>
                      <Link
                        to={`/country-regions/all/destinations/all`}
                        lang={i18n.language}
                      >
                        <Button
                          size='large'
                          color='red'
                          icon
                          labelPosition='right'
                          inverted
                          onClick={this.handleShowAll}
                        >
                          {t('country-page.show-all')}
                          <Icon name='ta-arrow-1-right' />
                        </Button>
                      </Link>
                    </div>
                  )}
                </Item.Group>
              </Grid.Column>

              <Grid.Column computer={5} tablet={16} mobile={16}>
                <GratisForm destinations={wishlistLinks} wishType='wishlist' />
              </Grid.Column>
            </Grid>
          </Segment>
        </Layout>
      </>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ wishlist }) => {
  return { wishlist }
}

const mapDispatchToProps = {
  wishlistRemoveItem: wishlistActions.wishlistRemoveItem,
  wishlistRemoveAll: wishlistActions.wishlistRemoveAll,
  toggleModal: modalActions.toggleModal
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(WishlistPage)
)
